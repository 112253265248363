import React from "react";

import { Container, Row, Col, Button } from 'react-bootstrap';

import { Layout } from '../../components/layout';
import { Masthead } from '../../components/masthead';

import Banner from '../../components/banner'
import Section from '../../components/section'

import "../../style/global.scss"

import mastheadImage from '../../images/masthead.jpg'


export default function Index() {
    return (
        <Layout title="Home | J Squared Design Services">
            <Masthead>
                <Masthead.Background>
                    <img className="w-100 h-100" style={{ 'objectFit': 'cover' }} src={mastheadImage} alt="" />
                </Masthead.Background>
            </Masthead>
            <Section>
                <Container>
                    <p><b>J Squared Design Services</b> focuses on providing high quality solutions tailored to suit each project and each customer they work with. We first serve the needs of the part with various types of action and material selection. Then we work to make the tool as close to an in-house design as we can to improve the flow of the tool through your shop.</p>
                    <p>Your designs reflect the best possible outcome for a build in your shop and we aim to match that by closely following mold standards and go-by designs provided to us. </p>
                    <p>Our designers are experienced with all facets of an injection mold design, including hot runners, three plates, rack driven rotating cores, hydraulic actions, and mechanical slides. </p>
                    <Row className="justify-content-md-center">
                        <Col md="12">
                            <h3>Design Features</h3>
                            <ul>
                                <li>Mechanical Slides</li>
                                <li>Hydraulic Slides</li>
                                <li>Rotating Cores</li>
                                <li>Hot Runners and Manifolds</li>
                                <li>3 Plate and Cold Runners</li>
                                <li>Parting-lines</li>
                            </ul>
                        </Col>
                        <Col md="12">
                            <h3>Materials</h3>
                            <ul>
                                <li>Steel</li>
                                <li>Specialty Steel</li>
                                <li>Beryllium Copper</li>
                                <li>Aluminum</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Banner>
                <Row>
                    <Col md="9" className="text-center text-md-left mb-3 mb-md-0">
                        <div className="d-flex h-100 align-items-center">If you are interested in our services, you can learn more about how we deliver quality designs, or contact us for more information.</div>
                    </Col>
                    
                    <Col md="3">
                        <Row className="text-center">
                            <Col><Button href="/about">About</Button></Col>
                        </Row>
                        <div className="my-2" />
                        <Row className="text-center">
                            <Col><Button href="/contact">Contact Us</Button></Col>
                        </Row>
                    </Col>
                </Row>
            </Banner>

        </Layout>
    )
}

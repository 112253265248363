import React from "react";
import { Container } from 'react-bootstrap';

import Section from '../section'

class Banner extends React.Component {
  render() {
    return (
      <Section className="banner">
        <Container>
          <div className="banner-inner text-center rounded w-lg-75 mx-auto">
            {this.props.children}
          </div>
        </Container>
      </Section>
    );
  }

  static Section = class extends React.Component {
    render() {
      return (
        <div className="my-5">
          {this.props.children}
        </div>
      );
    }
  }

  static Heading = class extends React.Component {
    render() {
      return (
        <h2 className={`section-heading mb-4`}>
          <span className="section-heading-upper">{this.props.upper}</span>
          <span className="section-heading-lower">{this.props.lower}</span>
        </h2>
      );
    }
  }
}

export default Banner;
